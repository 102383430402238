import React from "react";
import { Fade } from "react-awesome-reveal";
import "../styles/Footer.scss";
import facebook from "../images/redes/Caralibro.svg";
import Instagram from "../images/redes/Instagram.svg";
import appStore from "../images/App.svg";
import logo from "../images/logo.png";

const Footer = () => {
  return (
    <footer id="contacto">
      <div className="containerFooter">
        <div className="column1">
          <a href="./index.php">
            <img src={logo} alt="Logo Luvitec Vidrio y Aluminio CDMX" />
          </a>
          <div className="containerTextColumn1">
            <p>
              En Luvitec, nos especializamos en la instalación de productos en
              vidrio, aluminio y cristal templado. Atendemos proyectos
              residenciales y comerciales, garantizando seguridad y un diseño
              moderno. Ubicados al sur de la Ciudad de México.
            </p>
          </div>
        </div>

        <div className="column2">
          <div>
            <p>NOSOTROS</p>
            <div className="textColumn2">
              <a href="#inicio">
                <span>Conócenos</span>
              </a>
              <br />
              <a href="#porqueE">
                <span>¿Porque elegirnos?</span>
              </a>
              <br />
            </div>
          </div>
          <br />
          <div>
            <p>DATOS DE CONTACTO</p>
            <div className="textColumn2">
              <a href="mailto:info@luvitecvidrioyaluminio.com">
                <span>Envíanos un email</span>
              </a>
              <br />
              <a href="tel:+5215518252233">
                <span>5518252233</span>
              </a>
            </div>
          </div>
        </div>

        <div className="column3">
          <div>
            <p>SERVICIOS</p>
            <div className="textColumn3">
              <a href="#servicio1">
                <span>Diseño de Oficinas</span>
              </a>
              <br />
              <a href="#servicio2">
                <span>Proyectos Comerciales</span>
              </a>
              <br />
              <br />
              <span className="ubicacionTitle">UBICACIÓN</span>
              <br />
              <a href="https://maps.app.goo.gl/LPrkqcFCv2DJfoz38">
                <span id="ubicacion">
                Calz. del Hueso 341, Coapa, Girasoles III, Tlalpan, 14310 CDMX
                </span>
              </a>
              <br />
            </div>
          </div>
        </div>

        <div className="column4">
          <div>
            <p>APPS</p>
            <div>
              <span>Proximamente</span>
            </div>
            <div className="containerImgApp">
              <img src={appStore} alt="" />
            </div>
          </div>

          <div className="containerRedes">
            <Fade cascade="true" direction="left">
              <a href="https://www.facebook.com/luvitecVidrioAluminio/">
                <img src={facebook} alt="Facebook Luvitec" />
              </a>
            </Fade>
            <Fade cascade="true" direction="left">
              <a href="https://www.instagram.com/_luvitec_/">
                <img src={Instagram} alt="Instagram Luvitec" />
              </a>
            </Fade>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
