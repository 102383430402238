import React from "react";
import { Fade } from "react-awesome-reveal";
import Whats from "./Whats";
import "../styles/Services.scss";
import img1 from "../images/services/1.png";
import img2 from "../images/services/2.png";
import img3 from "../images/services/3.png";
import img4 from "../images/services/4.png";
import img5 from "../images/services/5.png";

const Services = () => {
  const servicios = [
    {
      id: 1,
      imagen: img1,
      titulo: "Diseño de Oficinas",
      texto:
        'Transforma tu oficina con nuestros elegantes diseños de cristal templado. Ofrecemos soluciones modernas y personalizadas que incluyen paredes, puertas y cerramientos completos. Disfruta de un ambiente profesional y luminoso con la calidad y durabilidad que solo el cristal templado puede ofrecer. Contáctanos para crear un espacio de trabajo único y funcional.',
    },
    {
      id: 2,
      imagen: img2,
      titulo: "Proyectos Comerciales",
      texto:
        "En Luvitec, somos expertos en proyectos comerciales e industriales con soluciones integrales en vidrio y aluminio. Diseñamos e instalamos puertas, ventanas, canceles y más, adaptándonos a las necesidades específicas de tu negocio. Ya sea para oficinas, locales comerciales o grandes espacios industriales, ofrecemos calidad y profesionalismo en cada detalle.",
    },
    {
      id: 3,
      imagen: img3,
      titulo: "Barandales de Cristal Templado",
      texto:
        "Ofrecemos una variedad de estilos para adaptarse a tus necesidades, ideales para interiores y exteriores. Perfectos para hogares, oficinas y proyectos comerciales, nuestros barandales combinan seguridad y modernidad con herrajes de acero inoxidable de alta calidad, garantizando durabilidad y resistencia. Mejora el diseño de tus escaleras, balcones y terrazas con nuestros sofisticados barandales de cristal templado, diseñados para brindar una solución estética y funcional. ¡Transforma tus espacios con Luvitec!",
    },
    {
      id: 4,
      imagen: img4,
      titulo: "Proyectos Residenciales",
      texto:
        "En Luvitec, nos especializamos en la creación y ejecución de proyectos residenciales. Desde la instalación de ventanas y puertas de aluminio hasta la colocación de canceles de cristal templado, ofrecemos soluciones que transforman cada espacio. Nuestro equipo se encarga de cada detalle, asegurando un acabado perfecto y una funcionalidad excepcional. Ya sea una remodelación o un proyecto desde cero, trabajamos contigo para hacer realidad tus ideas, utilizando materiales de la más alta calidad y diseños modernos.",
    },
    {
      id: 5,
      imagen: img5,
      titulo: "Fachadas para Edificios",
      texto:
        "En Luvitec, nos especializamos en fachadas para edificios, combinando diseño moderno y funcionalidad. Utilizamos vidrio y aluminio de alta calidad para crear fachadas contemporáneas, eficientes y duraderas. Desde la planificación hasta la instalación, nuestro equipo garantiza acabados excepcionales adaptados a las necesidades de cada proyecto.",
    },
  ];

  return (
    <div id="allServices">
      <h4 className="fw-bold title">NUESTROS SERVICIOS</h4>
      {servicios.map((servicio) => (
        <section
          className="cursosContainer"
          id={"servicio" + servicio.id}
          key={servicio.id}
        >
          <div className="imgContainer text-center">
            <Fade duration="1000">
              <img src={servicio.imagen} alt={servicio.titulo} />
            </Fade>
          </div>
          <div className="infoCursoContainer">
            <Fade duration="1000">
              <span className="titleService">
                <strong>{servicio.titulo}</strong>
              </span>
            </Fade>
            <span className="textService">{servicio.texto}</span>

            <div className="btnServiceContainer">
              <Whats
                classStyle="btn btnService"
                phoneNumber="5215518252233"
                message={`¡Me pudiera brindar más información acerca de ${servicio.titulo}!`}
                text="¡Más información!"
              />
            </div>
          </div>
        </section>
      ))}
    </div>
  );
};

export default Services;
